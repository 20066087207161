@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Pretendard", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Pretendard Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./font/Pretendard-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard Medium";
  font-style: normal;
  font-weight: 500;
  src: url("./font/Pretendard-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./font/Pretendard-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./font/Montserrat-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: 500;
  src: url("./font/Montserrat-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "Montserrat Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./font/Montserrat-Regular.ttf") format("opentype");
}

h1 {
  font-family: "Pretendard Bold", sans-serif;
}

h2 {
  font-family: "Pretendard Medium", sans-serif;
}

p {
  font-family: "Pretendard Regular", sans-serif;
}
